import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { useNavigate } from "react-router-dom";
import plusIcon from "../images/plus.svg";
import logoutIcon from "../images/ic_logout.svg";
import profileIcon from "../images/LOGOUT.png";
import Dropdown from "react-bootstrap/Dropdown";
import logo from "../images/logo3.png";
import { useWindowSize } from "@uidotdev/usehooks";

import appContext from "../context/appContext";

import { styled as styleds } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
const Nav = styled.div`
  backgroundcolor: #403151;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #403151;
  width: 330px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 0ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
  margin-left: 24px;
  margin-right: 24px;
`;

const Sidebar = ({ item_id }) => {
  const navigate = useNavigate();
  const size = useWindowSize();
  // console.log("width is: ", size);
  // const testSidebar = useRef(() => {
  //   if (size.width >= 960) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // });
  // console.log("dsadasdasd", testSidebar.current);
  const userType = localStorage.getItem("user_type");
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [sidebar, setSidebar] = useState(true);
  const [openCreateClassroomDialogue, setOpenCreateClassroomDialogue] =
    React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClickOpen = () => {
    navigate("/create-classroom", {
      state: {
        folder_id: selectedFolder !== null ? selectedFolder.folder_id : null,
      },
    });
  };
  const state = useContext(appContext);

  // const handleClose = () => {
  //   state.setCreateClassroomDialogue(false);
  // };

  const showSidebar = (e) => {
    e.preventDefault();
    setSidebar(!sidebar);
  };
  function onLogoutClick() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("student_name");
    localStorage.removeItem("user_type");
    localStorage.removeItem("email_address");
    localStorage.removeItem("quiz_taker");
    localStorage.removeItem("quiz_type");
    localStorage.removeItem("class_code");
    localStorage.removeItem("student");
    window.location.replace("/login");
  }
  useEffect(() => {
    // console.log("height and width of the screen", size);
    if (size.width >= 960) {
      setSidebar(true);
    } else {
      setSidebar(false);
    }
  }, [size]);

  var backgroundColor = "transparent";
  // useEffect(() => {
  //   console.log("CALLED");
  // }, [sidebar.current]);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      color: "#49454F",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "20px",
      borderRadius: "8px",
      opacity: "0.8",
      backgroundColor: "#FFF",
      padding: "12px 16px",
      maxWidth: 326,
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)", // Apply box shadow here
    },
  }));

  return (
    <>
      <div style={{ zIndex: "1000" }} className="sticky-div">
        <div className="w-full h-[60px] border-b border-[#E4E1E7] pr-[15px] sticky-div mb-[0px] flex">
          <div className="w-[50%]">
            {/* <NavIcon
              to="#"
              className={userType === "3" ? "hidden" : " pb-[10px] ml-[10px] "}
            >
              <FaIcons.FaBars
                onClick={(e) => showSidebar(e)}
                className={userType === "3" ? "hidden" : " pb-[10px] ml-[10px]"}
              />
            </NavIcon> */}
          </div>
          <div className="w-[50%]">
            <Dropdown className="float-right">
              <Dropdown.Toggle id="profile-dropdown" variant="none">
                <div style={{ paddingBottom: "0px", marginRight: "5px" }}>
                  <img alt="" src={profileIcon}></img>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu variant="light">
                <Dropdown.Item>
                  {localStorage.getItem("email_address")}
                </Dropdown.Item>

                <Dropdown.Divider />
                <Dropdown.Item onClick={onLogoutClick}>
                  <div className="w-full flex">
                    <img alt="" className="mr-[5px]" src={logoutIcon}></img>
                    Logout
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div
              className="float-right md:mr-[60px] h-[10]"
              style={{
                display:
                  userType === "2" || userType === "1" || userType === "3"
                    ? "block"
                    : "none",
              }}
            >
              <div onClick={handleClickOpen} className="mt-[15px] ml-[10px]">
                <button
                  style={{ width: "100%", height: "0%" }}
                  type="button"
                  className=""
                  onClick={handleClickOpen}
                >
                  <div className="inline-flex">
                    {/* <img
                      className="create-quiz-button-icon"
                      src={plusIcon}
                      alt=""
                    /> */}
                    {userType === "1" || userType === "3" ? (
                      <ClickAwayListener onClickAway={handleClose}>
                        <div>
                          <HtmlTooltip
                            enterTouchDelay={0}
                            leaveTouchDelay={10}
                            open={open}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            title={
                              <React.Fragment>
                                <div className="p-[12px]">
                                  <Typography
                                    color="inherit"
                                    className="heading-create-class-tooltip pb-[5px]"
                                  >
                                    🎓 Explore Classroom Dynamics!
                                  </Typography>
                                  <Typography className="sub-heading-create-class-tooltip pl-[25px] pb-[5px]">
                                    By creating a classroom, you can:
                                  </Typography>
                                  <div className="flex items-start first-button-create-class-tooltip pb-[5px] ">
                                    <li></li>
                                    <div className="flex-1 pl-0 ">
                                      👥 Easily enroll students and manage your
                                      class.
                                    </div>
                                  </div>

                                  <div className="flex items-start first-button-create-class-tooltip pb-[5px]">
                                    <li></li>
                                    <div className="flex-1 pl-0">
                                      📊 Gain valuable insights into each
                                      student's progress.
                                    </div>
                                  </div>

                                  <div className="flex items-start first-button-create-class-tooltip pb-[5px]">
                                    <li></li>
                                    <div className="flex-1 pl-0">
                                      📝 Assign and schedule quizzes with a few
                                      clicks.
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            }
                          >
                            <span
                              style={{
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                                color: "#403151",
                                fontWeight: "600",
                              }}
                            >
                              + Create Class
                            </span>
                          </HtmlTooltip>
                        </div>
                      </ClickAwayListener>
                    ) : null}
                  </div>
                </button>
              </div>
            </div>
            {/* Join classroom for Student */}
            <div
              className="float-right md:mr-[60px] h-[100%]"
              style={{
                display: userType === "4" ? "block" : "none",
              }}
            >
              <div className="mt-[10px]">
                <button
                  style={{ width: "100%" }}
                  type="button"
                  className="create-class-icon"
                >
                  <div className="inline-flex">
                    <img
                      className="create-quiz-button-icon"
                      src={plusIcon}
                      alt=""
                    />
                    <span>Join Class</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <IconContext.Provider value={{ color: "#F7F2F9" }}>
          <SidebarNav
            id="sidebarNav"
            sidebar={userType === "4" ? false : sidebar}
          >
            <SidebarWrap>
              <div className="sm:block md:hidden ">
                <NavIcon to="#">
                  <AiIcons.AiOutlineClose onClick={showSidebar} color="white" />
                </NavIcon>
              </div>
              <div className="flex w-full justify-center items-center mt-[15px]">
                <img
                  alt=""
                  className="teepee-sidebar-icon mt-[10px] self-center mb-[30px]"
                  style={{ cursor: "pointer", transform: "translateY(-20px)" }}
                  src={logo}
                  onClick={() => {
                    window.location.replace("/");
                  }}
                />
              </div>

              {/* <button
                style={{ width: "100%" }}
                type="button"
                className="create_quiz_button"
                onClick={handleClick}
              >
                <div className="inline-flex">
                  <img
                    className="create-quiz-button-icon"
                    src={plusIcon}
                    alt=""
                  />{" "}
                  <span>Create Quiz</span>
                </div>
              </button> */}

              {SidebarData.map((item, index) => {
                let expandSubNav = false;
                if (
                  item.id === "users_feedback" &&
                  (item_id === "users_feedback" ||
                    item_id === "evaluation_feedback")
                ) {
                  item.backgroundColor = "#7E418B";
                  item.color = "white";
                  expandSubNav = true;
                } else if (item_id === item.id) {
                  item.backgroundColor = "#7E418B";
                  item.color = "white";
                  expandSubNav = true;
                } else {
                  item.backgroundColor = "transparent";
                  item.color = "white";
                }

                if (
                  userType === "2" &&
                  item.id !== "tutorials" &&
                  item.id !== "view_subject_detail" &&
                  item.id !== "edit_predefined_questions" &&
                  item.id !== "tracking_analytics" &&
                  item.id !== "view_onboarding_subject"
                )
                  if (userType === "2") {
                    return (
                      <SubMenu
                        item_id={item_id}
                        expandSubNav={expandSubNav}
                        backgroundColor={backgroundColor}
                        item={item}
                        key={index}
                      />
                    );
                  }

                if (
                  (userType === "1" || userType === "3") &&
                  item.id !== "tutorials" &&
                  item.id !== "view_subject_detail" &&
                  item.id !== "edit_predefined_questions" &&
                  item.id !== "tracking_analytics" &&
                  item.id !== "view_onboarding_subject" &&
                  item.id !== "view_subject" &&
                  item.id !== "user_option" &&
                  item.id !== "users_feedback"
                )
                  if (userType === "1" || userType === "3")
                    return (
                      <SubMenu
                        item_id={item_id}
                        expandSubNav={expandSubNav}
                        item={item}
                        key={index}
                      />
                    );
                  else return null;
              })}
            </SidebarWrap>
          </SidebarNav>
        </IconContext.Provider>
      </div>
    </>
  );
};

export default Sidebar;
