import React, { useEffect } from 'react';


const ResetPassword = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
  
  
    var id = params.get("id");
  const handleClick = () => {

    window.location.href = `https://preview.page.link/geovise.page.link?link=https://geovise.page.link/resetpassword?id%3D${id}&apn=com.highersummit.geovise&ibi=com.highersummit.geovise`;
  };
useEffect(()=>{
    handleClick()
},[id])
  return (
    <div></div>
  );
};

export default ResetPassword;
