import React from "react";

import LoginPageRightSide from "./LoginPageRightPart";
import LoginPageLeftSide from "./LoginPageLeftSide";
import amplitude from "amplitude-js";

function Login() {
  // React.useEffect(() => {
  //   try {
  //     // amplitude.getInstance().setUserId(null);
  //     // amplitude.getInstance().logEvent("LP-Page View");
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, []);

  return (
    <React.Fragment>
      <section>
        <div className="w-auto h-auto grid  text-white text-4xl md:grid-cols-2 sm:grid-cols-1 overflow-hidden ">
          <LoginPageLeftSide />
          <LoginPageRightSide header="Welcome to Geovise" />
        </div>
      </section>
    </React.Fragment>
  );
}

export default Login;
