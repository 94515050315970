import { useContext } from "react";
import learnloopicon from "../images/logo3.png";
import Background from "../images/background.png";
import appContext from "../context/appContext";
import check from "../images/check_small.png";
function LoginPageLeftSide(props) {
  const state = useContext(appContext);
  const listItemStyle = {
    display: "flex", // Use flexbox to create a row with two columns
    alignItems: "center", // Align items vertically
    marginBottom: "8px", // Spacing between items
  };

  const checkMarkStyle = {
    color: "white", // Set the checkmark color to white
    marginRight: "8px",
    marginBottom: "15px", // Spacing between the checkmark and the text
  };

  return (
    <div
      className="centered w-full h-full bg-white md:h-screen p-5 md:p-32 items-center mt-0 md:mt-0 sm:pt-20"
      style={{ backgroundImage: `url(${Background})`, backgroundSize: "cover" }}
    >
      <div className="main-content-div w-[600px] sm:w-[360px] md:w-[520px]">
        <div className="align-center justify-center flex ">
          <img src={learnloopicon} alt="" />
          
        </div>
              <p style={{fontFamily:'Roboto',fontSize:'10px', letterSpacing:"1.5px", textAlign:'center'}}>Revolutionizing Retrieval Practice</p>
        <div
          className="teepee-heading mt-[48px]"
          style={{
            textAlign: "center",
            fontSize: "16px",
            lineHeight: "24px",
            color: "white",
            letterSpacing: "0.1px",
            fontWeight: "600",
            fontStyle: "normal",
          }}
        >
          <p>
            Geovise streamlines education by allowing teachers to share lesson
            plans,<br></br> auto-generating next-day quizzes, pinpointing and
            reteaching weak areas,<br></br> and reinforcing concept mastery with
            strategic recall.
          </p>
        </div>
        <div
          className="teepee-heading"
          style={{
            textAlign: "center",
            fontSize: "24px",
            color: "white",
            letterSpacing: "-0.12px",
            fontWeight: "700",
            fontStyle: "normal",
          }}
        >
          <div
            style={{
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "20px",
              paddingTop: "44px",
            }}
          >
            <span style={{ fontSize: "28px" }}>——</span> Here's What Teachers
            Can Do <span style={{ fontSize: "28px" }}>——</span>
          </div>
        </div>
        <div
          className="teepee-heading"
          style={{
            paddingTop: "24px",
            textAlign: "left",
            fontSize: "16px",
            color: "white",
            letterSpacing: "0.12px",
            fontWeight: "700",
            lineHeight: "16px",
            fontStyle: "normal",
            justifyContent: "center",
            display: "flex",
            gap: "32px",
          }}
        >
          <ul style={{ listStyleType: "none" }}>
            <li style={listItemStyle}>
              <span style={checkMarkStyle}>
                <img src={check} width={24} height={24} alt="" />
              </span>
              <span>
                Effective Recall Cycles:{" "}
                <span className="description">
                  Utilize our evidence-based recall<br></br> strategy to
                  significantly boost student's memory retention.
                </span>
              </span>
            </li>
            <li style={listItemStyle}>
              <span style={checkMarkStyle}>
                <img src={check} width={25} height={25} alt="" />
              </span>
              <span>
                Detailed Content Mapping:{" "}
                <span className="description">
                  Seamlessly integrate your<br></br> lessons within the
                  curriculum structure.
                </span>
              </span>
            </li>
            <li style={listItemStyle}>
              <span style={checkMarkStyle}>
                <img src={check} width={25} height={25} alt="" />
              </span>
              <span>
                Structured MCQs:{" "}
                <span className="description">
                  Engage students with quizzes designed <br></br>to solidify
                  their understanding.
                </span>
              </span>
            </li>
            <li style={{ ...listItemStyle, marginBottom: "10px" }}>
              <span style={checkMarkStyle}>
                <img src={check} width={25} height={25} alt="" />
              </span>
              <span>
                Instant Feedback:{" "}
                <span className="description">
                  Help students quickly comprehend errors<br></br> and grasp the
                  correct concepts.
                </span>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LoginPageLeftSide;
